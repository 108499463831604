import { Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import React from "react"
import Layout from "../components/layout/layout"

export default function Contact({ location }: { location: WindowLocation }) {
  return (
    <Layout title="Privacy verklaring" location={location}>
      <div className="page">
        <Heading as="h1">PRIVACYVERKLARING</Heading>

        <p>
          <br />
        </p>
        <p>
          Fanx B.V. respecteert de privacy van de bezoekers van de Website, in
          het bijzonder de rechten van bezoekers met betrekking tot de
          geautomatiseerde verwerking van persoonsgegevens. Vanwege volledige
          transparantie met onze klanten hebben wij daarom een beleid
          geformuleerd en geïmplementeerd met betrekking tot deze verwerkingen
          zelf, het doel ervan alsook de mogelijkheden voor betrokkenen om hun
          de rechten zo goed mogelijk te kunnen uitoefenen.
        </p>
        <p>
          <br />
        </p>
        <p>
          Voor alle aanvullende informatie over de bescherming van
          persoonsgegevens kunt u terecht op de website van de Autoriteit
          persoonsgegevens:
          <a href="https://autoriteitpersoonsgegevens.nl/nl">
            <u>
              <span>https://autoriteitpersoonsgegevens.nl/nl</span>
            </u>
          </a>
          .
        </p>
        <p>
          <br />
        </p>
        <p>
          Totdat u op de website het gebruik van cookies en andere tracking
          systems accepteert, plaatsen wij geen niet-geanonimiseerde analytische
          cookies en/of tracking cookies op uw computer, mobiele telefoon of
          tablet.
        </p>
        <p>
          Met het voortzetten van het bezoek van de Website accepteert u de
          volgende gebruikersvoorwaarden.
        </p>
        <p>
          <br />
        </p>
        <p>
          De huidige op de website beschikbare versie van de privacyverklaring
          is de enige versie die van toepassing is zolang u de website bezoekt,
          totdat een nieuwe versie de huidige versie vervangt.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 1 - Wettelijke bepalingen</b>
        </p>
        <p>
          <br />
        </p>
        <p>1. Website (hierna ook “de website”): Fairsy.nl</p>
        <p>
          <br />
        </p>
        <p>
          2. Verantwoordelijke voor de verwerking van persoonsgegevens (Hierna
          ook: “De beheerder”): Fanx B.V.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 2 - Toegang tot de website</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          De toegang tot de website en het gebruik is strikt persoonlijk. U zult
          deze website alsook de gegevens en informatie die daarop verstrekt
          worden niet gebruiken voor commerciële, politieke of publicitaire
          doeleinden, dan wel voor enige commerciële aanbiedingen en in het
          bijzonder niet gebruiken voor ongevraagde elektronische aanbiedingen.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 3 - De content van de website</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Alle merken, afbeeldingen, teksten, commentaren, illustraties,
          (animatie)plaatjes, videobeelden, geluiden, alsook alle technische
          applicaties die gebruikt kunnen worden om de website te laten
          functioneren en meer in het algemeen alle onderdelen die op deze site
          zijn gebruikt, zijn beschermd bij wet door intellectuele
          eigendomsrechten. Iedere reproductie, herhaling, gebruik of
          aanpassing, op welke wijze dan ook, van het geheel of slechts een
          onderdeel ervan, met inbegrip van de technische applicaties, zonder
          voorafgaande schriftelijke toestemming van de verantwoordelijke, is
          ten strengste verboden. Indien de beheerder niet meteen actie
          onderneemt tegen enig inbreuk, kan dat niet worden opgevat als
          stilzwijgende toestemming dan wel het afzien van rechtsvervolging.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 4 - Het beheer van de website</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Voor het goede beheer van de website kan de beheerder op ieder moment:
        </p>
        <p>
          <br />
        </p>
        <p>
          <span>
            • de toegang aan een bepaalde categorie bezoekers schorsen,
            onderbreken of beperken tot de gehele of een gedeelte van de website
            • alle informatie verwijderen die het functioneren van de website
            kan verstoren of in strijd is met nationale of internationale
          </span>
        </p>
        <p>wetgeving of in strijd is met internet-etiquette</p>
        <p>
          <span>
            • de website tijdelijk niet beschikbaar hebben teneinde updates uit
            te kunnen voeren
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 5 - Verantwoordelijkheden</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          De beheerder is in geen geval verantwoordelijk voor falen, storingen,
          moeilijkheden of onderbrekingen van het functioneren van de website,
          waardoor de website of een van zijn functionaliteiten niet
          toegankelijk is. De wijze waarop u verbinding zoekt met de website is
          uw eigen verantwoordelijkheid. U dient zelf alle geschikte maatregelen
          te treffen om uw apparatuur en uw gegevens te beschermen tegen onder
          andere virusaanvallen op het internet. U bent bovendien zelf
          verantwoordelijk voor de websites en de gegevens die u op internet
          raadpleegt.
        </p>
        <p>
          <br />
        </p>
        <p>
          De beheerder is niet aansprakelijk voor juridische procedures die
          tegen u worden gevoerd:
        </p>
        <p>
          <br />
        </p>
        <p>
          <span>
            • vanwege het gebruik van de website of diensten toegankelijk via
            internet{" "}
          </span>
        </p>
        <p>
          <span>
            • vanwege het schenden van de voorwaarden van deze privacy policy
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          De beheerder is niet verantwoordelijk voor enige schade die u zelf
          oploopt, dan wel derden of uw apparatuur oplopen als gevolg van uw
          verbinding met of het gebruik van de website. U zult zich onthouden
          van iedere actie tegen de beheerder als gevolg hiervan.
        </p>
        <p>
          <br />
        </p>
        <p>
          Indien de beheerder betrokken raakt bij een geschil als gevolg van uw
          gebruik van deze website, is hij gerechtigd alle schade die hij
          dientengevolge lijdt en nog zal lijden op u te verhalen.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 6 - Het verzamelen van gegevens</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Uw gegevens worden verzameld door Funx B.V.. Onder persoonsgegevens
          worden verstaan: alle informatie over een geïdentificeerde of
          identificeerbare natuurlijke persoon; als identificeerbaar wordt
          beschouwd een natuurlijke persoon die direct of indirect kan worden
          geïdentificeerd, met name aan de hand van een identificator zoals een
          naam, een identificatienummer, locatiegegevens, een online
          identificator of een of meer elementen die kenmerkend zijn voor de
          fysieke, fysiologische, genetische, psychische, economische, culturele
          of sociale identiteit.
        </p>
        <p>
          <br />
        </p>
        <p>
          De persoonsgegevens die op de website worden verzameld worden
          hoofdzakelijk gebruikt door de beheerder voor het onderhouden van
          relaties met u en indien aan de orde voor het verwerken van uw
          bestellingen.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 7 - Uw rechten met betrekking tot uw gegevens</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Op grond van artikel 13 lid 2 sub b AVG heeft eenieder recht op inzage
          van en rectificatie of wissing van zijn persoonsgegevens of beperking
          van de hem betreffende verwerking, alsmede het recht tegen de
          verwerking bezwaar te maken en het recht op gegevensoverdraagbaarheid.
          U kunt deze rechten uitoefenen door contact met ons op te nemen via
          fairsy24@gmail.com.
        </p>
        <p>
          <br />
        </p>
        <p>
          Ieder verzoek daartoe dient te worden vergezeld van een kopie van een
          geldig identiteitsbewijs, waarop u uw handtekening heeft gezet en
          onder vermelding van het adres waarop er met u contact kan worden
          opgenomen. Binnen 1 maand na het ingediende verzoek, krijgt u antwoord
          op uw verzoek. Afhankelijk van de complexiteit van de verzoeken en het
          aantal van de verzoeken kan deze termijn indien nodig
        </p>
        <p>met 2 maanden worden verlengd.</p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 8 - Verwerking van persoonsgegevens</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          In geval van schending van enige wet- of regelgeving, waarvan de
          bezoeker wordt verdacht en waarvoor de autoriteiten persoonsgegevens
          nodig hebben die de beheerder heeft verzameld, worden deze aan hen
          verstrekt na een uitdrukkelijk en gemotiveerd verzoek van die
          autoriteiten, waarna deze persoonsgegevens mitsdien niet meer onder de
          bescherming van de bepalingen van deze privacyverklaring
        </p>
        <p>vallen.</p>
        <p>
          <br />
        </p>
        <p>
          Indien bepaalde informatie noodzakelijk is om toegang te krijgen tot
          bepaalde functionaliteiten van de website, zal de verantwoordelijke
          het verplichte karakter van deze informatie aangeven op het moment van
          het vragen van de gegevens.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 9 - Commerciële aanbiedingen</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          U kunt commerciële aanbiedingen krijgen van de beheerder. Indien u
          deze niet (meer) wenst te ontvangen, stuurt u een mail naar het
          volgende adres: fairsy24@gmail.com .
        </p>
        <p>
          <br />
        </p>
        <p>
          Indien u tijdens het bezoek van de website enige persoonsgegevens
          tegenkomt, dient u zich te onthouden van het verzamelen ervan of van
          enig ander ongeoorloofd gebruik alsook van iedere daad die een inbreuk
          op de persoonlijke levenssfeer van die perso(o)n(en) oplevert. De
          beheerder is in geen geval verantwoordelijk in bovengenoemde
          situaties.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 10 - Bewaartermijn gegevens</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          De door de beheerder van website verzamelde gegevens worden gebruikt
          en bewaard voor de duur zoals deze bij wet is bepaald.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 11 - Cookies</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          1. Een cookie is een klein tekstbestand dat bij bezoek aan onze
          website geplaatst wordt op de harde schijf van uw computer. Een cookie
          bevat gegevens zodat u bij elk bezoek aan onze website als bezoeker
          kan worden herkend. Het is dan mogelijk om onze website speciaal op u
          in te stellen en het inloggen te vergemakkelijken.
        </p>
        <p>2. Wij gebruiken de volgende soorten cookies op onze website:</p>
        <p>
          <br />
        </p>
        <p>
          - Functionele cookies: zoals sessie- en login cookies voor het
          bijhouden van sessie- en inloginformatie. - Geanonimiseerde
          Analytische cookies: om inzage te krijgen in het bezoek aan onze
          website op basis van informatie over bezoekersaantallen, populaire
          pagina's en onderwerpen. Op deze wijze kunnen we de communicatie en
          informatievoorziening beter afstemmen op de behoeften van bezoekers
          van onze website. We kunnen niet zien wie onze websites bezoekt of
          vanaf welke pc het bezoek plaatsvindt.
        </p>
        <p>
          <br />
        </p>
        <p>3. Meer specifiek gebruiken wij de volgende cookies:</p>
        <p>- Geen overige cookies</p>
        <p>
          <br />
        </p>
        <p>
          4. Wanneer u onze website bezoekt kunnen cookies afkomstig van de
          verantwoordelijke en/of derden op uw apparatuur worden geïnstalleerd.
        </p>
        <p>
          5. Voor meer informatie over het gebruik, het beheer en het
          verwijderen van cookies voor elk besturingstype, nodigen wij u uit om
          de volgende link te raadplegen:
          <a href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies#faq">
            <u>
              <span>
                https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies#faq
              </span>
            </u>
          </a>
          .
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 12 - Beeldmateriaal en aangeboden producten</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Aan het beeldmateriaal dat behoort bij de aangeboden producten op de
          website kunnen geen rechten worden ontleend.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 13 - Toepasselijk recht</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Op deze voorwaarden is Nederlands Recht van toepassing. De rechtbank
          van de vestigingsplaats van de beheerder is exclusief bevoegd bij
          eventuele geschillen omtrent deze voorwaarden, behoudens wanneer
          hierop een wettelijke uitzondering van toepassing is.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 14 - Contact</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Voor vragen, productinformatie of informatie over de website zelf,
          kunt u zich richten tot: H.J. Bijmolt, fairsy24@gmail.com,
          06-83084433.
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Artikel 15 - Toeschrijving</b>
        </p>
        <p>
          <br />
        </p>
        <p>
          Deze privacyverklaring is gemaakt met behulp van Rocket Lawyer (
          <a href="https://www.rocketlawyer.com/nl/nl">
            <u>
              <span>https://www.rocketlawyer.com/nl/nl</span>
            </u>
          </a>
          ).
        </p>
        <p>
          <br />
        </p>
        <p>
          Deze privacyverklaring is van toepassing sinds 13-08-2021 tot nader
          order.
        </p>
      </div>
    </Layout>
  )
}
